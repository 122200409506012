import React, { useEffect, useRef } from 'react';
import s from './Line2.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll"


export const Line2 = () => {

  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: '0%',
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: '100%',
        ease: 'linear',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: '+=100px',
        },
      }
    );
  },[])

  return (
    <svg className={s.line} xmlns="http://www.w3.org/2000/svg" width="381" height="1112" viewBox="0 0 381 1112" fill="none">
      <path ref={lineRef} opacity="0.5" d="M380 0V911C380 1021.46 290.457 1111 180 1111H-163" stroke="#1B52AD" stroke-width="2"/>
    </svg>
  );
};
